var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BAlert",
    _vm._g(
      _vm._b(
        {
          attrs: { show: "", variant: "danger" },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              }),
              {
                key: "default",
                fn: function() {
                  return [
                    _c("BIconExclamationCircle", { staticClass: "mr-2" }),
                    _vm._t("default")
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        "BAlert",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }