import { render, staticRenderFns } from "./ErrorFallback.vue?vue&type=template&id=601b7416&scoped=true&"
import script from "./ErrorFallback.vue?vue&type=script&lang=js&"
export * from "./ErrorFallback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "601b7416",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2384520030/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('601b7416')) {
      api.createRecord('601b7416', component.options)
    } else {
      api.reload('601b7416', component.options)
    }
    module.hot.accept("./ErrorFallback.vue?vue&type=template&id=601b7416&scoped=true&", function () {
      api.rerender('601b7416', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ErrorFallback.vue"
export default component.exports